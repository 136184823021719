export default function useScryfall() {
	const fetcher = async (url) => {
		try {
			const response = await fetch(url);
			const data = await response.json();
			return data;
		} catch {
			return null;
		}
	};

	return {
		fetcher
	};
}