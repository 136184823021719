import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSession from "../../../utils/hooks/useSession";
import login from "../../../utils/auth/login";

export default function LoginForm() {
	const session = useSession();
	const navigate = useNavigate();
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
	});

	useEffect(() => {
		if (session.status === "done" && session.user) {
			navigate("/");
		}
	}, [session]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setInvalidEmail(false);
		setInvalidPassword(false);
		const email = event.target.email.value;
		const password = event.target.password.value;

		// Validation
		if (email === "") {
			setInvalidEmail(true);
		}

		if (password === "") {
			setInvalidPassword(true);
		}

		if (email === "" || password === "") {
			return;
		}

		const response = await login(email, password);
		
		if (!response?.user) {
			setInvalidEmail(true);
			setInvalidPassword(true);
		}

	};

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};

	const hasError = (condition) => {
		if (condition) {
			return "bg-red";
		}
		return "bg-dark-300";
	};

	return (
		<div className="bg-dark-200/50 rounded backdrop-blur border border-green px-8 pt-6 pb-8 mb-4 flex flex-col w-full max-w-full mx-6 md:mx-auto md:max-w-[600px]">
			<form onSubmit={handleSubmit}>
				<div className="mb-4">
					<input
						className={`bg-dark-300 text-light appearance-none rounded w-full py-2 px-3 ${hasError(invalidEmail)}`}
						id="email"
						type="text"
						placeholder="E-mail"
						onChange={handleChange}
					/>
				</div>
				<div className="mb-6">
					<input
						className={`text-light appearance-none rounded w-full py-2 px-3 mb-3 ${hasError(invalidPassword)}`}
						id="password"
						type="password"
						placeholder="******************"
						onChange={handleChange}
					/>
				</div>
				<div className="flex items-center justify-between">
					<button
						className="bg-dark-300 transition border border-dark-300 hover:border-green shadow text-green py-2 px-4 rounded"
						type="submit"
					>
          Sign In
					</button>
					<a
						className="inline-block align-baseline text-sm text-light hover:text-green"
						href="."
					>
          Forgot Password?
					</a>
				</div>

			</form>
			
		</div>
	);
}
