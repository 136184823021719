import PropTypes from "prop-types";
import Header from "../Header.jsx";
import { useNavigate } from "react-router-dom";
import useSession from "../../../utils/hooks/useSession";
import { useEffect } from "react";

export default function DefaultLayout({ children }) {
	const session = useSession();
	const navigate = useNavigate();

	useEffect(() => {
		if (session.status === "done" && !session.user) {
			navigate("/login");
		}
	}, [session]);

	if (session.status === "pending" || !session.user) return;

	return (
		<section className="h-screen w-screen bg-dark-300">
			<Header />
			<div className="container mx-auto text-light">
				{ children }
			</div>
		</section>
	);

}

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
};