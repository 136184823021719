import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default async function login(email, password) {
	const auth = getAuth();
    
	try {
		const user = await signInWithEmailAndPassword(auth, email, password);
		return {
			user,
			status: 200
		};
	} catch {
		return {
			user: null,
			status: 500
		};

	}
		
}