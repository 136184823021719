import { initializeApp } from "firebase/app";

export default function initFirebase() {
	return initializeApp({
		apiKey: "AIzaSyCYloYDQppZXKMALVZC4XaxdwkCRzn7dvE",
		authDomain: "undercity-c0be9.firebaseapp.com",
		projectId: "undercity-c0be9",
		storageBucket: "undercity-c0be9.appspot.com",
		messagingSenderId: "33729008620",
		appId: "1:33729008620:web:c542394f7216b26da5a6c9",
		databaseURL: "https://undercity-c0be9-default-rtdb.europe-west1.firebasedatabase.app"
	});
}