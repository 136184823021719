import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function Tile({ url }) {
	return (
		<Link className="w-full flex justify-center flex-col items-center border bg-dark-200 rounded p-4 shadow shadow-dark-200" to={url} >
			<div className="max-w-[50%]">
				<img src="" />
			</div>
		</Link>
	);
}

Tile.propTypes = {
	url: PropTypes.string.isRequired
};