import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

export default function useSession() {
	const [session, setSession] = useState({
		user: null,
		status: "pending"
	});
	const auth = getAuth();

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setSession({
					user,
					status: "done"
				});
			} else {
				setSession({
					user: null,
					status: "done"
				});
			}
		});
	}, [onAuthStateChanged]);

	return session;
}