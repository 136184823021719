import { getAuth, signOut } from "firebase/auth";

export default async function logout() {
	const auth = getAuth();
    
	try {
		const response = await signOut(auth);
		return {
			response,
			status: 200
		};
	} catch {
		return {
			response: null,
			status: 500
		};
	}
}