import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<main className="h-screen w-full flex flex-col justify-center items-center bg-dark-300">
			<h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
			<div className="bg-green px-2 text-sm rounded rotate-12 absolute">
		Page Not Found
			</div>
			<button className="mt-5">
				<span
					className="relative inline-block text-sm font-medium text-green group focus:outline-none focus:ring"
				>
					<span
						className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-green group-hover:translate-y-0 group-hover:translate-x-0"
					></span>

					<span className="relative block px-8 py-3 bg-dark-300 border border-current">
						<Link to="/">Start over</Link>
					</span>
				</span>
			</button>
		</main>
	);
}