
import initFirebase from "./utils/firebase";
import initAuth from "./utils/auth";
import initDatabase from "./utils/database";
import initStorage from "./utils/storage";
import initRouter from "./utils/router";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";

// Firebase
const firebaseApp = initFirebase();

// Authentication
initAuth(firebaseApp);

// Realtime database
initDatabase(firebaseApp);

// Storage
initStorage(firebaseApp);

// React Router
const router = initRouter();

// React query
const queryClient = new QueryClient();

// Service worker
if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("/serviceworker.js")
		.then((registration) => {
			console.log("Service Worker registered with scope:", registration.scope);
		})
		.catch((error) => {
			console.error("Service Worker registration failed:", error);
		});
}

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>		
	</React.StrictMode>
);