import DefaultLayout from "../../libs/features/Layout/Default";
import Root from "../../libs/features/Root";
import Login from "../../libs/features/Login";
import NotFound from "../../libs/features/NotFound";
import { createBrowserRouter } from "react-router-dom";
import Diablo from "../../libs/features/sections/Diablo";
import Magic from "../../libs/features/sections/Magic";

export default function initRouter() {
	return createBrowserRouter([
		{
			path: "/*",
			element: (
				<DefaultLayout>
					<Root />
				</DefaultLayout>
			),
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/diablo",
			element: (
				<DefaultLayout>
					<Diablo />
				</DefaultLayout>)
		},
		{
			path: "/magic",
			element: (
				<DefaultLayout>
					<Magic />
				</DefaultLayout>
			)
		},
		{
			path: "*",
			element: <NotFound />
		}
	]);
}