import { getDatabase, ref, set, child, get } from "firebase/database";

export async function writeCardData(userId, cardData) {
	if (!userId || !cardData) {
		return;
	}
    
	const db = getDatabase();
	const dbRef = ref(getDatabase());

	try {
		// First get from DB to verify if not already collected
		const snapshot = await get(child(dbRef, "users/" + userId + "/cards/" + cardData.id));

		if (snapshot.exists()) {
			console.log("exists: ", snapshot.val());
			set(ref(db, "users/" + userId + "/cards/" + cardData.id), {
				...cardData,
				quantity: snapshot.val().quantity + 1
			});
            
			return "duplicate";
		}

		set(ref(db, "users/" + userId + "/cards/" + cardData.id), {
			...cardData,
			quantity: 1
		});

		return "done";
	} catch (e) {
		console.error(e);
	}
}