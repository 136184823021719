import { Link, NavLink , useNavigate } from "react-router-dom";
import logout from "../../../utils/auth/logout";
import { useState } from "react";


export default function Header() {
	const mobileLinkClass = "block py-2 pl-3 pr-4 text-dark-200 md:bg-transparent md:p-0 md:text-light hover:text-light/70";
	const activeMobileLinkClass = "bg-dark-300/20 rounded md:underline " + mobileLinkClass;
	const [toggle, setToggle] = useState();
	const navigate = useNavigate();

	async function handleLogout() {
		await logout();
		navigate("/login");
	}

	function handleMenuClick() {
		setToggle(!toggle);
	}

	return (
		<header className="pb-[100px]">			
			<nav className="bg-dark-200/50 h-[69px] fixed w-full z-20 top-0 left-0 border-b border-green backdrop-blur">
				<div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
					<Link to="/" className="flex items-center">
						<span className="self-center text-2xl font-semibold whitespace-nowrap text-light">UNDERCITY</span>
					</Link>
					<div className="flex md:order-2">
						<button type="button" onClick={() => handleLogout()} className="border border-light transition-colors text-light hover:bg-light/10 focus:ring-1 focus:outline-none focus:ring-green rounded text-sm px-4 py-2 text-center mr-3 md:mr-0">Logout</button>
						<button type="button" onClick={() => handleMenuClick()} className="border border-light inline-flex items-center p-2 text-sm text-gray-500 rounded md:hidden hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-green">
							<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
						</button>
					</div>
					<div className={`${toggle ? "block" : "hidden"} items-center justify-between w-full md:flex md:w-auto md:order-1`}>
						<ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-dark-200 rounded bg-light md:bg-transparent md:flex-row md:space-x-8 md:mt-0 md:border-0">
							<li>
								<NavLink to="/" className={({ isActive }) => isActive ? activeMobileLinkClass : (`md:text-dark-300/80  ${mobileLinkClass}`)	}>Home</NavLink>
							</li>
							<li>
								<NavLink to ="/collection" className={({ isActive }) => isActive ? activeMobileLinkClass : (`md:text-dark-300/80  ${mobileLinkClass}`)	}>My collection</NavLink>
							</li>
							<li>
								<NavLink to ="/trading" className={({ isActive }) => isActive ? activeMobileLinkClass : (`md:text-dark-300/80  ${mobileLinkClass}`)	}>Trading</NavLink>
							</li>
							<li>
								<NavLink to ="/contact" className={({ isActive }) => isActive ? activeMobileLinkClass : (`md:text-dark-300/80  ${mobileLinkClass}`)	}>Contact</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>

		</header>

	);
}