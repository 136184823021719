import { useQuery } from "@tanstack/react-query";
import useScryfall from "../../../../utils/hooks/useScryfall";
import { useEffect, useRef, useState } from "react";
import { writeCardData } from "../../../../utils/database/cards/addCard";
import { getAuth } from "firebase/auth";

export default function Magic() {
	const auth = getAuth();
	const userId = auth.currentUser?.uid;
	const searchRef = useRef("");
	const cardData = useRef(null);
	const [cardImage, setCardImage] = useState("");
	const [status, setStatus] = useState(null);
	const [timer, setTimer] = useState(false);
	const [buttonText, setButtonText] = useState("Add to my collection");
	const { fetcher } = useScryfall();
	const { refetch } = useQuery({
		queryKey: ["search", searchRef],
		queryFn: () => fetcher(`https://api.scryfall.com/cards/named?fuzzy=${searchRef.current}`),
		staleTime: 15 * 60 * 1000,
		enabled: false
	});

	useEffect(() => {
		if (status === "done" || status === "duplicate") {
			setButtonText("Card was added to your collection");
			setTimeout(() => {
				setButtonText("Add to my collection");
			}, 5000);
		}
		if (status === "duplicate") {
			setTimer(true);
			setTimeout(() => {
				setTimer(false);
			}, 5000);
		}
	}, [status]);

	const handleSubmit = (event) => {
		event.preventDefault();
		const value = event.target.cardSearch.value;
		if (value === "") return;

		searchRef.current = event.target.cardSearch.value;
		refetch().then(result => {
			cardData.current = result.data;
			setCardImage(result.data?.image_uris?.png);
		}).catch(error => {
			console.error(error);
		});
	};

	const handleAddCollection = async() => {
		const status = await writeCardData(userId, cardData.current);
		console.log(status);
		setStatus(status);
	};

	return (
		<div className="flex flex-col items-center pb-6">
			<form onSubmit={handleSubmit} className="w-full max-w-[600px]">
				<div>
					<div className="mx-4 mb-4 md:flex md:mx-auto md:mb-auto md:items-center">
						<input id="cardSearch" type="text" placeholder="Search" className="bg-dark-300 text-light appearance-none md:rounded w-full md:h-full mb-2 md:mb-0 py-2 px-3 border mr-6" />
						<button type="submit" className="w-full md:w-auto hover:text-green transition-colors border py-2 md:py-3 px-6 md:rounded">Search</button>
					</div>
				</div>
			</form>
			<div className="hidden md:block">
				{
					cardImage && <button type="submit" onClick={() => handleAddCollection()} className="w-full md:w-auto hover:text-green transition-colors border py-2 md:py-3 md:my-4 px-6 md:rounded border-green">{buttonText}</button>
				}            
			</div>
			<div className={`${cardImage ? "block" : "hidden"} relative md:border md:p-6 w-full max-w-[600px] flex justify-center`}>
				<p className={timer ? "absolute top-[5px] mx-4 bg-dark-200 p-2 border border-green text-green transition-all opacity-100 h-auto w-auto" : "w-0 h-0 opacity-0"}>This card was already in your collection and was added again.</p>
				{
					cardImage && <img src={cardImage} alt="Card search result" className="w-full max-w-[350px] sm:max-w-[400px] md:max-w-[350px] p-2" />
				}
			</div>
			<div className="md:hidden">
				{
					cardImage && <button type="submit" onClick={() => handleAddCollection()} className="w-full md:w-auto hover:text-green transition-colors border py-2 md:py-3 md:my-4 px-6 md:rounded border-green">{buttonText}</button>
				}            
			</div>
		</div>
	);
}