import LoginForm from "../../components/LoginForm";
  
export default function Login() {
	return (
		<div className="relative h-screen w-screen bg-dark-300 overflow-hidden">
			<div className="absolute blur-sm opacity-25 flex justify-center w-full md:hidden">
				<img src="/bg.webp" className="scale-150 min-h-screen" />
			</div>	
			<section className="container mx-auto">
				<div className="flex items-center justify-center h-screen">
					<LoginForm />
				</div>
			</section>					
		</div>
	);
}
